<template>
  <section>
    <section>
      <va-breadcrumbs>
        <va-breadcrumbs-item label="Escolas" to="/educacao/escolas" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #ff7f27"
            >Notas dos Alunos</span
          >
        </va-breadcrumbs-item>
      </va-breadcrumbs>

      <h2 class="titulo-links-escolha">Notas</h2>

      <div class="row">
          <va-data-table
            v-if="alunos.length"
            striped="true"
            hoverable="true"
            animated="true"
            :items="alunos"
            :columns="columns"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #cell(id)="{ source: id }">
              <va-button class="mr-2 mb-2" size="small" color="success" @click="mostrar_notas(id)"> {{ $t('Notas') }}</va-button>
            </template>

            <template #bodyAppend>
              <tr>
                <td colspan="8" class="table-example--pagination">
                  <va-pagination v-model="currentPage" input :pages="pages" />
                </td>
              </tr>
            </template>
          </va-data-table>

          <div class="col-md-12" v-else>
            Ainda não existe Nota nessa Turma!
          </div>
      </div>

    </section>
  </section>
</template>


<script>
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { DisciplinaEscolar } from "@/class/disciplina";
import { Nota } from "@/class/nota";

export default defineComponent({
  props:{
    turma_id:{},
  },
  data () {
    const columns = [
      { key: "aluno", label: "Aluno", sortable: true },
      { key: "disciplina", label: "Disciplina", sortable: true },
      { key: "periodo_1", label: "1° Periodo", sortable: true },
      { key: "periodo_2", label: "2° Periodo", sortable: true },
      { key: "periodo_3", label: "3° Periodo", sortable: true },
      { key: "periodo_4", label: "4° Periodo", sortable: true },
      { key: "recuperacao_final", label: "Recuperação Final" },
      { key: "passou", label: "Passou"},
    ];
    return {
      columns,
      escola:null,
      escolas:[],
      turma:null,
      turmas: [],
      alunos: [],
    }
  },
  methods:{
    async listarNotasTurma() {
        try {
            if(this.turma_id){
                let data = await Nota.NotaAlunos(this.turma_id);
                for (const el of data.data) {
                    let passou = 'Não';
                    if(el.notas[0].passou != 0){
                      passou = 'Sim';
                    }

                    let novo = {
                        aluno: el.aluno.nome,
                        periodo_1: el.notas[0].periodo_1,
                        periodo_2: el.notas[0].periodo_2,
                        periodo_3: el.notas[0].periodo_3,
                        periodo_4: el.notas[0].periodo_4,
                        recuperacao_final: el.notas[0].recuperacao_final,
                        passou: passou,
                        disciplina: el.notas[0].disciplina,
                    }
                    this.alunos.push(novo);
                }
            }
        } catch (e) {

        }
    },

  },
  async beforeMount() {
    await this.listarNotasTurma();
  }
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>
